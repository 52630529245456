import React, { useEffect, useState } from "react";
import { IconButton, ImageList, ImageListItem, ImageListItemBar, Popover } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { BACKEND, POSTER_PATH_BASE } from "../constants";

function Postergrid() {

    // const [movies, setMovies] = useState([]);
    const demoItem = {title: 'Pickpocket', director: 'Robert Bresson', year: '1959', 
    description: "Michel takes up pickpocketing on a lark and is arrested soon after. His mother dies shortly after his release, and despite the objections of his only friend, Jacques, and his mother's neighbor Jeanne, Michel teams up with a couple of petty thieves in order to improve his craft. With a police inspector keeping an eye on him, Michel also tries to get a straight job, but the temptation to steal is hard to resist.", 
    link: 'https://image.tmdb.org/t/p/w200/woHPgAqD6Jf2UwbM9dqdssBaeEO.jpg'};
    const [images, setImages] = useState([{title: '', director: '', year: '', description: '', link: ''}]);

    // Card stuff
    const [anchorEl, setAnchorEl] = useState(null);
    const [desc, setDesc] = useState('');

    useEffect(() => {
       // update();
       // setUrls();
        console.log("kerran")
       fetch(BACKEND)
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              console.log("error");
            }
          })
          .then((data) => {
            // setMovies(data);
            data.forEach((e, i) => {
                let t = '', y = '', d = '', de = '';
                let tConcatenated = e.title.replace(/ /g, '+');
                t = e.title;
                y = e.year;
                d = e.director;
                de = e.desc;
                let queryUrl = `https://api.themoviedb.org/3/search/movie?api_key=09556e4c4359b6d0176d300a95fe9d33&query=${tConcatenated}&year=${y}`;
        
                // Empty the array to avoid multiples
                setImages([]);
                // Fetch poster for each URL
                fetch(queryUrl)
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        console.log("setUrls error");
                    }
                })
                .then((data) => {
                    let src = (POSTER_PATH_BASE) + data.results[0].poster_path;
                    setImages(images => [...images, {title: t, director: d, year: y, description: de, link: src}]);
                });
            });
          })
          .catch((err) => console.error(err));

    }, []);

    const handleClick = (e, item) => {
        setAnchorEl(e.currentTarget);
        setDesc(item);
    }

    const handleClose = () => {
        setAnchorEl(null);
        setDesc('');
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    // Gets movie array
    /* const update = () => {
        fetch(BACKEND)
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              console.log("error");
            }
          })
          .then((data) => {
            // console.log(data)
            setMovies(data);
            //console.log(movies);
          })
          .catch((err) => console.error(err));
    } */

    // Get poster URL and add relevant data to images
    /* const setUrls = () => {
        movies.forEach((e, i) => {
            let t = '', y = '', d = '', de = '';
            let tConcatenated = e.title.replace(/ /g, '+');
            t = e.title;
            y = e.year;
            d = e.director;
            de = e.desc;
            let queryUrl = `https://api.themoviedb.org/3/search/movie?api_key=09556e4c4359b6d0176d300a95fe9d33&query=${tConcatenated}&year=${y}`;

            // Empty the array to avoid multiples
            setImages([]);
            // Fetch poster for each URL
            fetch(queryUrl)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    console.log("setUrls error");
                }
            })
            .then((data) => {
                let src = (POSTER_PATH_BASE) + data.results[0].poster_path;
                setImages(images => [...images, {title: t, director: d, year: y, description: de, link: src}]);
            });
        });
        // console.log(images);
    } */

    

    return (
        <div>
            {/*<button onClick={() => { update(); setUrls() }}>Update list</button> <br />*/}
            <a href="https://moviedb-ptv8.onrender.com/">Visit Backend</a>
            <div align="center">
                <ImageList sx={{ width: 800, height: 600 }} cols={4}>
                    <ImageListItem style={{maxHeight: '300px'}}>
                        <img
                            src={`${demoItem.link}`} // Backticks in case need for URL params
                            alt={demoItem.title}
                        />
                        <ImageListItemBar 
                            title={demoItem.title}
                            subtitle={`${demoItem.director}, ${demoItem.year}`}
                            actionIcon={
                                <IconButton aria-describedby={id} color='info' onClick={event => handleClick(event, demoItem.description)}>
                                    <InfoIcon />
                                </IconButton>
                            }
                        />
                    </ImageListItem>
                    {images.map((item, i) => (
                        <ImageListItem key={i} style={{maxHeight: '300px'}}>
                            <img
                            src={`${item.link}`} // Backticks in case need for URL params
                            alt={item.title}
                            />
                            <ImageListItemBar 
                            title={item.title}
                            subtitle={`${item.director}, ${item.year}`}
                            actionIcon={
                                <IconButton aria-describedby={id} color='info' onClick={event => handleClick(event, item.description)}>
                                    <InfoIcon />
                                </IconButton>
                            }
                            />
                        </ImageListItem>
                    ))}
                    
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        elevation={1}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                          }}
                    >
                        <div
                        style={{
                            width: '200px',
                            margin: '5px',
                            
                        }}>
                            {desc}
                        </div>
                    </Popover>
                </ImageList>

                
            </div>
        </div>
    );
}

export default Postergrid;